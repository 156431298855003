<template>
  <div v-if="visible">
    <el-dialog
      v-ismove :visible="true"
      :title="_id ? '编辑产品线角色' : '创建产品线角色'"
      width="600px" :close-on-click-modal="false"
      :before-close="closeTab">
      <el-form ref="formData" :model="formData" :rules="rules" size="small" :inline="false" label-width="120px">
        <el-form-item label="产品线角色名称" prop="roleName">
          <el-input v-model="formData.roleName" placeholder="请输入" clearable :style="inputWidth"></el-input>
        </el-form-item>
        <el-form-item label="分配人员">
          <el-select v-model="formData.userIds" clearable multiple filterable remote
            :remote-method="remoteMethod" placeholder="可输入名称查询" :style="inputWidth">
            <el-option v-for="item in users" :key="item.userId"
              :label="item.name+'('+item.username+')'" :value="item.userId">
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="备注">
          <el-input type="textarea" :rows="3" v-model="formData.description" :style="inputWidth" clearable></el-input>
        </el-form-item>
      </el-form>
      <div slot="footer">
        <el-button size="small" @click="closeTab">取消</el-button>
        <el-button type="primary" size="small" @click="submit" :loading="waiting">确定</el-button>
      </div>
    </el-dialog>
  </div>
</template>
<script>
import * as validFn from '@/utils/validate'
import { deepClone } from '@/utils/index'
import { roleAdd, roleUpdate } from '@/api/role'
export default {
  data() {
    return {
      formData: {
        roleName: '',
        userIds: [],
        description: ''
      },
      rules: {
        roleName: [validFn.required()]
      },
      users: [],
      inputWidth: 'width: 400px',
      waiting: false
    }
  },
  props: {
    visible: {
      type: Boolean,
      default: false
    },
    _id: {
      type: Number,
      default: null
    },
    roleName: {
      type: String,
      default: ''
    },
    idList: {
      type: Array,
      default: function() {
        return []
      }
    },
    userList: {
      type: Array,
      default: function() {
        return []
      }
    },
    description: {
      type: String,
      default: ''
    }
  },
  watch: {
    'visible'(val) {
      if (val) {
        console.log(this.userList)
        // 数据量太大影响加载，初始默认截取100条数据(加上回填的数据)
        const has = this.userList.filter(item => this.idList.includes(item.userId))
        const array = this.userList.slice(0, 100)
        const array_ids = array.map(item => item.userId)
        has.forEach(item => {
          if (!array_ids.includes(item.userId)) {
            array.unshift(item)
          }
        })
        this.users = array
        this.formData.roleName = this.roleName
        this.formData.userIds = this.idList
        this.formData.description = this.description
      }
    }
  },
  methods: {
    closeTab() {
      this.$emit('update:visible', false)
      this.$refs.formData.resetFields()
    },
    remoteMethod(val) {
      if (val) {
        setTimeout(() => {
          this.users = this.userList.filter(item => {
            return (item.name.indexOf(val) > -1 || item.username.toLowerCase().indexOf(val.toLowerCase()) > -1)
          })
        }, 200)
      }
    },
    submit() {
      this.$refs.formData.validate(valid => {
        if (valid) {
          console.log(this.formData)
          if (this._id) {
            const params = deepClone(this.formData)
            params.roleId = this._id
            this.waiting = true
            roleUpdate(params).then(res => {
              if (res.code === '000000') {
                this.$refs.formData.resetFields()
                this.$emit('success')
                this.$message.success(res.message)
              }
              this.waiting = false
            }).catch(() => {
              this.waiting = false
            })
          } else {
            this.waiting = true
            roleAdd(this.formData).then(res => {
              if (res.code === '000000') {
                this.$refs.formData.resetFields()
                this.$emit('success')
                this.$message.success(res.message)
              }
              this.waiting = false
            }).catch(() => {
              this.waiting = false
            })
          }
        } else {
          return false
        }
      })
    }
  }
}
</script>
