<template>
  <div class="app-container">
    <el-form :model="searchForm" inline size="small">
      <el-form-item label="产品线角色编码">
        <el-input v-model="searchForm.roleCode" placeholder="请输入" clearable></el-input>
      </el-form-item>
      <el-form-item label="产品线角色名称">
        <el-input v-model="searchForm.roleName" placeholder="请输入" clearable></el-input>
      </el-form-item>
      <el-form-item label="分配人员姓名">
        <el-input v-model="searchForm.userName" placeholder="请输入" clearable></el-input>
      </el-form-item>
      <el-button type="primary" size="small" @click="search">查询</el-button>
      <el-button type="success" size="small" @click="optRole(null)">新增</el-button>
    </el-form>
    <el-table v-loading="loading" :data="dataList" size="small" border :header-cell-style="{background: '#41A1FF', color:'#fff'}" height="calc(100vh - 220px)">
      <el-table-column label="产品线角色名称" prop="roleName" width="130" align="center"></el-table-column>
      <el-table-column label="产品线角色编码" prop="roleCode" width="180" align="center"></el-table-column>
      <el-table-column label="产品线">
        <template slot-scope="scope">
          <span v-for="(item, index) in scope.row.productLineList" :key="index">
            {{ item.plName }}
          <span v-if="index < scope.row.productLineList.length - 1">，</span>
          </span>
        </template>
      </el-table-column>
      <el-table-column label="创建人" prop="name" width="120" align="center"></el-table-column>
      <el-table-column label="分配的人员" prop="userName" :show-overflow-tooltip="true" width="120" align="center">
         <template slot-scope="scope">
         <span>{{scope.row.names.toString()}}</span>
        </template>
      </el-table-column>
      <el-table-column label="创建时间" prop="updateTime" width="120" align="center"></el-table-column>
      <el-table-column label="状态" width="120" align="center">
        <template slot-scope="scope">
          <el-switch
            v-model="scope.row.status"
            :active-value="1"
            :inactive-value="0"
            active-color="#13ce66"
            inactive-color="#ff4949"
            @change="changeStatus($event, scope.row)">
          </el-switch>
        </template>
      </el-table-column>
      <el-table-column label="操作" width="140" align="center">
        <template slot-scope="scope">
          <el-tooltip effect="dark" content="权限" placement="top">
            <svg-icon icon-class="empower" style="width: 16px;height:16px;cursor:pointer;margin-right:10px;" @click="empower(scope.row)"></svg-icon>
          </el-tooltip>
          <el-tooltip effect="dark" content="编辑" placement="top">
            <i class="table_icons el-icon-edit" @click="optRole(scope.row)"></i>
          </el-tooltip>
          <el-tooltip effect="dark" content="删除" placement="top">
            <i class="table_icons el-icon-delete" @click="delRole(scope.row.roleId)"></i>
          </el-tooltip>
        </template>
      </el-table-column>
    </el-table>
    <div style="text-align:center;margin-top:1em;">
      <el-pagination
        :current-page="pageNum"
        :page-size="pageSize"
        :total="total"
        :page-sizes="[10, 20, 50, 100]"
        layout="total, sizes, prev, pager, next, jumper"
        @size-change="handleSizeChange"
        @current-change="handleCurrentChange">
      </el-pagination>
    </div>
    <!-- 新增 编辑 -->
    <add-panel v-bind.sync="optionsObj" @success="success"></add-panel>
    <!-- 配置产品线 -->
    <product-line v-bind.sync="productLine" @success="success"></product-line>
  </div>
</template>
<script>
import { getRole, roleUpdate, roleDelete, getProductLine } from '@/api/role'
// import { getUserInfoByName } from '@/api/login'
import { deepClone, cleanParams } from '@/utils/index'
import addPanel from './addPanel'
import productLine from './productLine'
import { getUserInfoByIdList } from '@/api/login'
export default {
  components: { addPanel, productLine },
  data() {
    return {
      searchForm: {
        roleCode: '',
        roleName: '',
        userName: ''
      },
      total: 0,
      pageSize: 10,
      pageNum: 1,
      loading: false,
      dataList: [],
      // userList: [],
      productLineList: [],
      optionsObj: {
        visible: false,
        _id: null,
        roleName: '',
        idList: [],
        description: ''
      },
      productLine: {
        visible: false,
        lineIds: [],
        lineList: [],
        _id: null
      }
    }
  },
  computed: {
    userList() {
      return this.$store.getters.allUserList
    }
  },
  mounted() {
    this.getDataList()
    // this.getAllUser()
    this.getAllLine()
    if (this.userList && this.userList.length < 1) {
      this.$store.dispatch('GetAllUser')
    }
  },
  methods: {
    // 所有人员
    // getAllUser() {
    //   this.userList = []
    //   getUserInfoByName({ pageSize: 10000, pageNum: 1 }).then(res => {
    //     if (res.code === '000000') {
    //       this.userList = res.data.list
    //     }
    //   }).catch()
    // },
    // 所有产品线
    getAllLine() {
      this.productLineList = []
      getProductLine({ pageSize: 1000, pageNum: 1 }).then(res => {
        if (res.code === '000000') {
          this.productLineList = res.data.list
        }
      }).catch()
    },
    search() {
      if (this.pageNum === 1) {
        this.getDataList()
      } else {
        this.handleCurrentChange(1)
      }
    },
    getDataList() {
      const params = cleanParams(deepClone(this.searchForm))
      params.pageSize = this.pageSize
      params.pageNum = this.pageNum
      this.loading = true
      getRole(params).then(res => {
        if (res.code === '000000') {
          const list = res.data.list
          this.total = res.data.total
          let arr = []
          list.forEach(ele => {
            if (ele.userIds.length > 0) {
              arr = arr.concat(ele.userIds)
            }
          })
          this.getUserNameEmail(Array.from(new Set([...arr])), list).then((resd) => {
            this.dataList = resd || []
          }).catch(() => {

          })
        } else {
          this.total = 0
          this.dataList = []
        }
        this.loading = false
      }).catch(err => {
        console.log(err)
        this.loading = false
        this.total = 0
        this.dataList = []
      })
    },
    getUserNameEmail(allIds, data) {
      // 查所有人名和邮箱
      return new Promise(function(resolve, reject) {
        getUserInfoByIdList({
          userIds: allIds
        }).then(res => {
          const userData = res.data
          data.forEach(element => {
            const ids = element.userIds
            element.names = []
            if (ids.length > 0) {
              ids.forEach(id => {
                const obj = userData.find(u => id === u.userId)
                element.names.push(obj.name)
              })
            }
          })
          resolve(data)
        })
      })
    },
    handleSizeChange(size) {
      this.pageSize = size
      this.getDataList()
    },
    handleCurrentChange(page) {
      this.pageNum = page
      this.getDataList()
    },
    changeStatus(val, item) {
      const str = val === 1 ? '确定启用该产品线角色？' : '确定禁用该产品线角色？'
      this.$confirm(str, '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        const params = {
          roleId: item.roleId,
          status: val
        }
        this.loading = true
        roleUpdate(params).then((res) => {
          if (res.code === '000000') {
            this.$message.success(res.message)
            this.getDataList()
          }
          this.loading = false
        }).catch(() => {
          this.loading = false
        })
      }).catch(() => {
        item.status = val === 1 ? 0 : 1
      })
    },
    empower(item) {
      console.log(item)
      this.productLine = {
        visible: true,
        lineIds: item.productLineList ? item.productLineList.map(el => { return el.plId }) : [],
        lineList: this.productLineList,
        _id: item.roleId
      }
    },
    optRole(item) {
      this.optionsObj.userList = this.userList
      this.optionsObj.visible = true
      if (item) {
        this.optionsObj._id = item.roleId
        this.optionsObj.roleName = item.roleName
        this.optionsObj.idList = item.userIds
        this.optionsObj.description = item.description
      } else {
        this.optionsObj._id = null
        this.optionsObj.roleName = ''
        this.optionsObj.idList = []
        this.optionsObj.description = ''
      }
    },
    delRole(id) {
      this.$confirm('确定删除该条数据？', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        roleDelete(id).then(res => {
          if (res.code === '000000') {
            this.$message.success(res.message)
            this.getDataList()
          }
        }).catch()
      }).catch(() => {
        console.log('已取消')
      })
    },
    success() {
      this.optionsObj.visible = false
      this.productLine.visible = false
      this.getDataList()
    }
  }
}
</script>
