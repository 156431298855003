<template>
  <div v-if="visible">
    <el-dialog v-ismove :visible="true" width="700px" title="配置产品线"
      :close-on-click-modal="false" :before-close="closeTab">
      <div style="padding: 0 20px;">
        <el-checkbox :indeterminate="isIndeterminate" v-model="allChecked" @change="changeAll">全选</el-checkbox>
        <el-checkbox-group v-model="productLine" @change="changeSelect">
          <el-row :gutter="20">
            <el-col :span="6" v-for="item in lineList" :key="item.plId" style="margin-bottom:10px;">
              <el-checkbox :label="item.plId">{{item.plName}}</el-checkbox>
            </el-col>
          </el-row>
        </el-checkbox-group>
        <div slot="footer" style="text-align:right;margin-top:2em">
          <el-button size="small" @click="closeTab">取消</el-button>
          <el-button type="primary" size="small" @click="submit" :loading="waiting">确定</el-button>
        </div>
      </div>
    </el-dialog>
  </div>
</template>
<script>
import { updateRights } from '@/api/role'
export default {
  data() {
    return {
      allChecked: false,
      isIndeterminate: true,
      productLine: [],
      waiting: false
    }
  },
  props: {
    lineList: {
      type: Array,
      default: function() {
        return []
      }
    },
    lineIds: {
      type: Array,
      default: function() {
        return []
      }
    },
    visible: {
      type: Boolean,
      default: false
    },
    _id: {
      type: Number,
      default: null
    }
  },
  watch: {
    'visible'(val) {
      if (val) {
        this.allChecked = false
        this.productLine = this.lineIds

        this.changeSelect(this.productLine)
      }
    }
  },
  methods: {
    closeTab() {
      this.productLine = []
      this.$emit('update:visible', false)
    },
    submit() {
      const params = {
        roleId: this._id,
        userId: this.$store.getters.roles.userInfo.userId,
        productLineIds: this.productLine
      }
      this.waiting = true
      updateRights(params).then(res => {
        if (res.code === '000000') {
          this.$message.success(res.message)
          this.$emit('success')
        }
        this.waiting = false
      }).catch(() => {
        this.waiting = false
      })
    },
    changeAll(val) {
      this.productLine = val && this.lineList.length > 0 ? this.lineList.map(item => item.plId) : []
      this.isIndeterminate = false
    },
    changeSelect(val) {
      this.allChecked = val.length === this.lineList.length
      this.isIndeterminate = val.length > 0 && val.length < this.lineList.length
    }
  }
}
</script>
